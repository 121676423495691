import React, { FC, useCallback } from 'react'
import { Play, Stop } from '@styled-icons/boxicons-regular'
import styled, { css } from 'styled-components'
import { volumeAtom } from '../state/playerConfig'
import { useAtom } from 'jotai'
import { useAudioPlayer } from '../autioPlayer/useAudioPlayer'

const buttonStyle = css`
  cursor: pointer;
  color: #13bba4;


  &:hover {
    color: #0f8574;
  }

  &:active {
    color: #095e52;
  }
`

const StyledPlay = styled(Play)`
  ${buttonStyle}
`
const StyledStop = styled(Stop)`
  ${buttonStyle}
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Player: FC = () => {
  const url =
    window.location.hash.slice(1) || 'https://riddim-stream.henrik.ninja/live?app=riddim&stream=mopidy'
  const [volume, setVolume] = useAtom(volumeAtom)
  const { play, stop, playing} = useAudioPlayer(url, Math.pow(volume, 2))

  const handleVolumeChange = useCallback(
    (e) => {
      setVolume(e.currentTarget.value)
    },
    [setVolume]
  )

  return (
    <Wrapper>
      <input
        type="range"
        step="any"
        min="0"
        max="1"
        onChange={handleVolumeChange}
        value={volume}
      />
      {playing ? (
        <StyledStop size={200} onClick={stop} />
      ) : (
        <StyledPlay size={200} onClick={play} />
      )}
    </Wrapper>
  )
}

export default Player

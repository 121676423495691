import mpegts from 'mpegts.js'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useToast } from '@chakra-ui/react'

export const useAudioPlayer = (url: string, volume: number) => {
  const audioElement = useRef(document.createElement('audio'))
  const toast = useToast()

  const playerRef = useRef(
    mpegts.createPlayer(
      { type: 'mse', isLive: true, url: url },
      { liveBufferLatencyChasing: true }
    )
  )

  const [playing, setPlaying] = useState(false)

  useEffect(() => {
    audioElement.current.volume = volume
  }, [volume])

  const play = useCallback(() => {
    setPlaying(true)
  }, [])

  const stop = useCallback(() => {
    setPlaying(false)
  }, [])

  useEffect(() => {
    if (!playing) {
      return
    }
    const player = playerRef.current

    let retries = 0

    let retryTimeout: NodeJS.Timeout | undefined

    const playPlayer = () => {
      toast({
        title: 'Connecting...',
      })

      player.attachMediaElement(audioElement.current)
      player.load()
      Promise.resolve(player.play()).catch((err) => {
        playerRef.current.unload()
        if (err.name === 'AbortError') {
          // Was aborted because we pressed stop before the audio was loaded
          return
        }

        toast({
          title: 'Playback error',
          description: err.message,
          status: 'error',
        })
      })
    }

    const retry = () => {
      if (retryTimeout) {
        return
      }

      retries++
      const timeout = Math.min(retries * 250, 7500)
      console.log(`Retrying in ${timeout}`)

      retryTimeout = setTimeout(() => {
        retryTimeout = undefined

        playPlayer()
      }, timeout)
    }

    const handleError = (err: any, details: any, errObj: any) => {
      player.pause()
      player.unload()
      player.detachMediaElement()

      toast({
        title: `MpegTS error: ${err}`,
        description: `${details}: ${JSON.stringify(errObj)}`,
        status: 'error',
      })

      retry()
    }
    player.on(mpegts.Events.ERROR, handleError)

    const handleMediaInfo = (e: any) => {
      toast({ title: 'Connected', status: 'success' })
    }

    player.on(mpegts.Events.METADATA_ARRIVED, handleMediaInfo)

    playPlayer()

    const stats = setInterval(() => {
      const { buffered, readyState, duration } = audioElement.current
      console.log({ buffered: buffered.length, readyState, duration })
    }, 10000)

    return () => {
      player.off(mpegts.Events.ERROR, handleError)
      player.off(mpegts.Events.METADATA_ARRIVED, handleMediaInfo)

      player.detachMediaElement()
      // player.pause()
      player.unload()
      if (retryTimeout) {
        clearTimeout(retryTimeout)
      }

      clearInterval(stats)

      toast({
        title: 'Disconnected',
      })
    }
  }, [play, playing, stop, toast])

  return { play, stop, playing }
}
